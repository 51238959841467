import { 
  parseDataPersona,
  parseDataRegistroAbogado,
  parseDataCertificacionTitulo,
  parseDataDirecciones,
  parseDataProfesion
} from './parseDataRegistro';

import {
  formatDateGlobal,
  getDiasRestantes
} from './funcionesFecha'

import {verificiarCredencialVencida} from './credencial'

export default {
  methods: {
    getDatosRegistro() {
      if (!this.$store.getters.estaRegistrado) {
        this.$store.commit('setMessageOverlay', 'Verificando datos del registro ...')
        this.$store.commit('setOverlay', true);
        if(this.$vuetify.breakpoint.xs){
          this.$store.commit('setDrawer',false)
        }
        const path='/rpa'
        if(this.$router.history.current.path !== path){
           this.$router.push(path)
        }        
        this.$http.get(`${this.$apiUrl}abogado-ciudadano/`).then(response => {
          if (response.data.error == false) {
           
            this.$store.commit('abogado/setIsRegistered', true);
            const abogado = response.data.data;
            const registroAbogado = parseDataRegistroAbogado(abogado)
            const {
              estadoAbogado,
              estadoRegistro,
              tipoRegistro,
              fechaCredencialVencimiento
            } = registroAbogado
            
            this.$store.commit('abogado/setFechaCorteCredencial',abogado.fecha_corte_credencial||null)
            this.$store.commit('abogado/setRegistroAbogado', registroAbogado);
            this.$store.commit('abogado/setTipoRegistro', tipoRegistro);
            this.$store.commit('abogado/setEstadoAbogado', estadoAbogado);
            this.$store.commit('abogado/setEstadoRegistro', estadoRegistro);

            if (abogado.certificacion_titulo !== null) {
              this.$store.commit('abogado/setCertificacionTitulo',parseDataCertificacionTitulo(abogado));
            }
           
            this.$store.commit('abogado/setPersona', parseDataPersona(abogado.persona));
            this.$store.commit('abogado/setDirecciones', parseDataDirecciones(abogado.persona.direcciones));
            
            this.$store.commit('abogado/setProfesion', parseDataProfesion(abogado));

            this.$store.commit('abogado/setCopiaLegalizada', abogado.fotocopia_titulo_legalizada);
            this.$store.commit('abogado/setConCodigoPago', abogado.con_codigo_pago);

            this.getCptsCiudadano();

            if(estadoAbogado === 'CONCLUIDO'){
              this.verificarVencimientoCredencial(fechaCredencialVencimiento)
            }

            if(estadoAbogado === 'INHABILITADO'){
              verificiarCredencialVencida(fechaCredencialVencimiento)
              // TODO verificarSanciones
            }

            if(estadoAbogado === 'PENDIENTE DE FIRMA'){
              this.$store.commit('addAlerta',{
                id: 'REGISTRO-0001',
                type: 'warning',
                text: 'Usted tiene una solicitud pendiente de firma por REGISTRO DE ABOGADO'
              })
            }

            if(abogado.cantidad_sanciones_pendientes>0){
              this.$store.commit('addAlerta',{
                id: 'SANCION-999999',
                type: 'error',
                text: `Usted tiene registrada ${abogado.cantidad_sanciones_pendientes} SANCION(ES) ve a la opcion sanciones para mas informacion`
              })
            }

            this.verificarModulosUsuario();

            const tipoCredencial = abogado.tipo_credencial

            const existeTransicion = tipoCredencial.codigo === 'CRED-001' && estadoAbogado === 'CONCLUIDO'

            if(existeTransicion){
              this.$store.commit('abogado/setTransicionDigital',true)
            }

            const credencialDigital = abogado.credenciales.find(e=>e.tipo==='DIGITAL' && e.activo && e.dispositivo===null)
            if(credencialDigital){
              this.$store.commit('abogado/setCredencialDigitalNoVinculada',true)
            }

            this.consultado = true;
            this.$store.commit('setOverlay', false);

            if(this.$storage.exist('continue')){
              this.$router.push(this.$storage.get('continue'))
              this.$storage.remove('continue')
            }
            
            
          }
        }).catch(error => {
          this.$store.commit('setOverlay', false);
          this.$store.commit('abogado/setIsRegistered', false);
          console.log(error);
        })
      }
    },
    verificarModulosUsuario() {
      if (this.isRegistered) {
        // this.$store.commit('addItemsMenu', [
        //   {
        //     home: true,
        //     descripcion:
        //       "Verifica las Solicitudes de Certificaciones Solicitadas",
        //     icon: "mdi-content-copy", text: "CERTIFICACION", path: "/abogado/certificaciones",
        //     posicion: 3,
        //     titulo: 'Certificación',
        //     imagen:
        //       "https://apirpa2.justicia.gob.bo/imagenes/certificacion_abogado.png"
        //   }
        // ]);
      }
      if (this.isRegistered && this.$store.state.abogado.estadoAbogado == "CONCLUIDO") {
        this.$store.commit('addItemsMenu', [
          {
            home: true,
            descripcion: "Solicite su reposición de la credencial por extravío o la renovación por caducidad, deterioro, retificacion o actualización de datos.",
            icon: "mdi-card-account-details", text: "REPOSICION/RENOVACION", path: "/abogado/credenciales",
            posicion: 2,
            titulo: 'Reposición y Renovación de la Credencial',
            imagen:
              "https://apirpa2.justicia.gob.bo/imagenes/credenciales.png"
          },
             {
            home: true,
            descripcion:
              "Verifica las Solicitudes de Certificaciones Solicitadas",
            icon: "mdi-content-copy", text: "CERTIFICACION", path: "/abogado/certificaciones",
            posicion: 3,
            titulo: 'Certificación',
            imagen:
              "https://apirpa2.justicia.gob.bo/imagenes/certificacion_abogado.png"
          },
          {
            home: true,
            permiso: "ROLE_DEFENSOR_LISTAR",
            posicion: 4,
            titulo: 'Registro RUANNA',
            descripcion:
              "Registro unico de Abogadas, Abogados para la defensa Técnica, Gratuita y Especializada de Niñas, Niños y Adolecentes Víctimas de Delitos de Violencia",
            icon: "mdi-account-check-outline", text: "RUANNA", path: "/ruanna",
            imagen: "https://apirpa2.justicia.gob.bo/imagenes/ruanna.png"
          },
          {
            home: true,
            titulo: 'Sanciones',
            descripcion:
              "Control del Ejercicio Prfesional, Registro de sanciones contra abogados",
            path: "/abogado/sanciones",
            icon: 'mdi-gavel',
            text: 'SANCIONES',
            posicion: 5,
            imagen: "https://apirpa2.justicia.gob.bo/imagenes/sanciones.jpg"
          },
          {
            home: true,
            titulo: 'POSTULACIÓN',
            descripcion:
              "Postulate a la Convocatoria del Tribunal de Ética de la Unidad de Registro Público de la Abogacia",
            path: "/convocatorias",
            icon: 'mdi-bullhorn',
            text: 'POSTULACIÓN',
            posicion: 6,
            nuevo:true,
            imagen: "https://www.ugelmoho.gob.pe/web1/wp-content/uploads/2020/10/convocatoria.png"
          },
          {
            home: true,
            titulo: 'SOCIEDADES CIVILES',
            descripcion:
              "Registro de sociedades civiles",
            path: "/abogado/sociedades-civiles",
            icon: 'mdi-account-group',
            text: 'SOCIEDADES CIVILES',
            posicion: 9,
            imagen: "https://www.thefulfillmentlab.com/hubfs/Temp%20Images/business%20partnership%20illustration.png"
          }
        ]);
      }
      if (this.isRegistered && this.$store.state.abogado.credencialVencido) {
        this.$store.commit('resetItemsMenu')
        this.$store.commit('addItemsMenu', [
          {
            home: false,
            icon: "mdi-view-dashboard",
            text: "INICIO",
            titulo: 'Registro Público de La Abogacia',
            path: "/rpa",
            posicion: 0,
          },
          {
            home: true,
            descripcion: "Solicite su reposición de la credencial por extravío o la renovación por caducidad, deterioro, retificacion o actualización de datos.",
            icon: "mdi-card-account-details", text: "REPOSICION/RENOVACION", path: "/abogado/credenciales",
            posicion: 2,
            titulo: 'Reposición y Renovación de la Credencial',
            imagen:
              "https://apirpa2.justicia.gob.bo/imagenes/credenciales.png"
          }
        ]);
      }
      return;
    },
    getCptRegistroAbogado(){
     this.$store.commit('setAlertas',[])
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/metodo-pago-registro`)
      .then(response=>{
          const {data} = response.data
          if(data !== null){
            this.$store.commit('abogado/setMetodoPago',data)
          }
      }).catch(error=>{
          console.log('------------------------------------');
          console.log(error);
          console.log('------------------------------------');
      })
    },
    getCptsCiudadano(){
        this.$store.commit('setAlertas',[])
        this.$http.get(`${this.$apiUrl}abogado-ciudadano/codigo-pago`)
        .then(response=>{
            const cpts = response.data.data
            // const id = 0;
            cpts.forEach(cpt => {
              if(cpt.estado == 'CREADO'){
                this.$store.commit('addAlerta',{
                  id: `CPT-${cpt.idCpt}`,
                  type: 'error',
                  text: `Usted tiene un pago pendiente de CPT por motivo de ${cpt.tipoTramite}`
                })
                if(cpt.tipoTramite === 'REGISTRO ABOGADO'){
                  this.$store.commit('abogado/setCpt',cpt)
                }
              }
            });
        }).catch(error=>{
          console.log(error)
        })
        this.getSolicitudPasarelaPago()
    },
    getSolicitudPasarelaPago(){
      this.$store.commit('abogado/setMetodoPago',{
        solicitudPasarelaPago:null,
        cpt:null,
        qr:null
      })
      this.$store.dispatch('deleteAlertasPasarela')
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago`)
      .then(response=>{
          const solicitudes = response.data.data
          // const id = 0;
          solicitudes.forEach(solicitud => {
            if(['CREADO','SOLICITADO','EN_PROCESO'].includes(solicitud.estado) && solicitud.activo){
              this.$store.commit('addAlerta',{
                id: `PPE-${solicitud.id}`,
                type: 'error',
                text: `Usted tiene una solicitud de pago mediante la PASARELA DE PAGOS por motivo de ${solicitud.tipoTramite}`
              })
              // if(solicitud.tipoTramite === 'REGISTRO ABOGADO'){
              //   this.$store.commit('abogado/setMetodoPago',{
              //     solicitudPasarelaPago:solicitud,
              //     cpt:null,
              //     qr:null
              //   })
              // }
            }
          });
      }).catch(error=>{
        console.log(error)
      })
    },
    verificarVencimientoCredencial(fechaVencimiento){
      if(!fechaVencimiento) return
      const diferenciaEnDias =  getDiasRestantes(fechaVencimiento)
      if(diferenciaEnDias >= 0 && diferenciaEnDias < 60){
        const diasText = diferenciaEnDias ===0?'hoy':`en ${diferenciaEnDias} dias calendario`
        this.$store.commit('addAlerta',{
          id: 'CREDENCIAL-0001',
          type: 'warning',
          class:'black--text',
          text : `Su credencial emitida vencerá ${diasText}, debes realizar la renovación de la misma por caducidad, posteriormente se procederá al bloqueo de servicios en el (SRA)`
        })
      }
      if(diferenciaEnDias<0){
        this.$store.commit('addAlerta',{
          id: 'CREDENCIAL-0002',
          type: 'error',
          text :`Su credencial se encuentra caducada la misma venció en fecha ${formatDateGlobal(fechaVencimiento)}, debes realizar la renovación por caducidad antes de que se proceda al bloqueo de servicios en el (SRA)`
        })
      }
    }
  }
}