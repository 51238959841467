export const moduleMenu = {
    state: {
        drawer: true,
        clipped: false,
        miniVariant: true,
        tituloPrincipal: 'Registro Público de la Abogacia',
        itemsMenu: localStorage.rpa_menu ? JSON.parse(localStorage.rpa_menu) : [
        {
            home: false,
            icon: "mdi-view-dashboard",
            text: "INICIO",
            titulo: 'Registro Público de La Abogacia',
            path: "/rpa",
            posicion: 0,
        },
        {
            home: true,
            descripcion: "Realice su registro y seguimiento",
            icon: "mdi-contacts",
            text: "REGISTRO",
            path: "/abogado",
            titulo: 'Registro y Matriculación',
            posicion: 1,
            imagen:
                "https://apirpa2.justicia.gob.bo/imagenes/registro_abogado.jpg"
        },
        {
            home: true,
            descripcion:
                "Cursos, Capacitaciones y seminarios",
            path: "/cursos",
            icon: "mdi-school",
            text: "CURSOS",
            titulo: 'Cursos',
            posicion: 6,
            imagen: "https://apirpa2.justicia.gob.bo/imagenes/capacitacion.jpg"
        },
        {
            home: true,
            descripcion:
                "Busqeda de abogados matriculados a Nivel Nacional",
            path: "/buscar",
            icon: "mdi-account-search",
            text: "BUSQUEDA",
            titulo: 'Busqueda',
            posicion: 7,
            imagen: "https://apirpa2.justicia.gob.bo/imagenes/busqueda.png"
        }
       /* {
            home: true,
            descripcion:
                "Inscribite o as Seguimiento a los Cursos de Capacitacion y actualizacion",
            ruta: "/cursos",
            icon: "mdi-school",
            text: "CURSOS",
            titulo: 'Cursos',
            posicion: 7,
            imagen: "https://apirpa2.justicia.gob.bo/imagenes/capacitacion.jpg"
        },*/
        ],
    },
    mutations: {
        setDrawer(state, payload) {
            state.drawer = payload;
        },
        setClipped(state, payload) {
            state.clipped = payload
        },
        setMiniVariant(state, payload) {
            state.miniVariant = payload
        },
        resetItemsMenu(state) {
            state.itemsMenu = []
        },
        addItemsMenu(state, payload) {
            payload.forEach(element => {
                var contain = false
                    state.itemsMenu.forEach(menu => {
                    if(menu.titulo == element.titulo){
                        contain = true
                    }
                    });
                    if(!contain){
                    state.itemsMenu.push(element)
                    }
            })
        },
        setTituloPricipal(state, payload) {
            state.tituloPrincipal = payload
        }
    },
    getters:{
        getMenuItemsByPosicion(state){
            return state.itemsMenu.sort((a, b) =>
            a.posicion > b.posicion ? 1 : -1
          );
        }
    }
}