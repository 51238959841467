<template>
  <v-container fluid fill-height >
    <v-layout align-center justify-center>
      <v-flex lg8 md10 sm12>
        <v-card class="elevation-20 d-flex align-center" :min-height="$vuetify.breakpoint.xs?'550':'450'">
          <v-card-text>
            <v-container grid-list-lg>
              <v-layout row wrap align-center justify-center class="d-flex align-center">
                <v-flex lg5 md5 sm6 xs12>
                  <v-img
                  :src="require('@/assets/logo-vertical-minjus-2021.png')"
                  lazy
                  :aspect-ratio="$vuetify.breakpoint.xs?'2':$vuetify.breakpoint.lg?'1.7':'2'"
                  contain
                ></v-img>
                </v-flex>
                <v-flex lg4 md4 offset-lg1 offset-md1>                    
                  <h2 class="mb-4 text-center">Sistema de Registro de la Abogacía (SRA)</h2>
                  <p class="subtitle-2 text-justify">Bienvenido, a través de esta plataforma podrá realizar trámites en la Unidad de Registro Público de la Abogacía con su cuenta de Ciudadanía Digital, para iniciar sesión presione en el botón.
                  </p>
                  <v-row justify="center" >
                    <v-btn
                      block
                      x-large
                      color="#90bc1d"
                      outlined
                      rounded
                      @click="start_session_ciudadania()"
                      :loading="loading_ciudadania"
                    >
                      <img src="@/assets/btn_ciudadania_digital_v2.png" width="130" />
                    </v-btn>
                    <p class="pt-5 mb-2 text--secondary">¿No tienes cuenta en ciudadania? <br v-if="$vuetify.breakpoint.xs"><a href="https://www.gob.bo/ciudadania/registrate" target="_blank" style="text-decoration: none" class="blue--text">Registrate aquí</a></p>
                    <p class="text--secondary">¿Olvidaste tu contraseña? <a href="https://www.gob.bo/ciudadania/password" target="_blank" style="text-decoration: none" class="blue--text">Presiona aquí</a></p>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <div class="help">
          <h3>
            <span>Si tiene dudas o consultas</span>
          </h3>
          <p>
            <span>Contáctese con nosotros:</span>
          </p>
          <p>
            <span>
              <strong>Dirección General de Registro, Control y Supervisión del Ejercicio de la Abogacía</strong>
              <v-icon> mdi-phone </v-icon>(591-2) 2158900 - 2158901 Internos. 3023
            </span>
          </p>
          <!-- <p>
            <span>
              <strong>Área Tecnologias Informacion Comunicación:</strong>
              <v-icon> mdi-phone </v-icon> (591-2) 2158900 - 2158901 Interno. 1801
            </span>
          </p> -->
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import openId from "@/mixins/openid"
import mixinRegistro from "@/mixins/registro"
import mixinAuth from "@/mixins/auth";
import { mapState } from "vuex";
export default {
  mixins:[openId, mixinRegistro, mixinAuth],
  data(){
    return {
      loading_ciudadania:false,
      url:process.env.VUE_APP_URL,
      eye: true,
      valid: false,
      formLogin: {
        username: "",
        password: ""
      },
      snackbar: false,
      usuarioRules: [v => !!v || "Usuario es requerido"],
      passwordRules: [v => !!v || "Contraseña es requerido"],
      isLoading: false,
      fullPage: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      parametros:state=>state.session.parametros
    }),
    error() {
      return this.$store.state.application.error;
    }
  },
  watch: {
    error(value) {
      if (value) {
        this.snackbar = true;
      }
    },
    snackbar(value) {
      if (!value) {
        this.$store.commit("setError", null);
      }
    }
  },
  mounted() {
   this.tokenOpenID();
   const params = new URLSearchParams(window.location.search);
   if (params.get('redirect') && params.get('transicion')) {
      this.$storage.set("redirect", params.get('redirect'));
      this.$storage.set("transicion",true);
      this.start_session_ciudadania()
   }
  },
  methods: {
    start_session_ciudadania() {
      this.$Progress.start();
      this.loginOpenID();
    }
  }
};
</script>
<style>
.help {
  text-align: center;
  padding: 30px 0px 0px;
  color: #666666;
}
</style>
