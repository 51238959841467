<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="clipped"
    :mini-variant="miniVariant"
    mini-variant-width="80"
    app
    :expand-on-hover="$vuetify.breakpoint.mdAndUp"
  >
    <v-list-item two-line class="px-3">
      <v-list-item-avatar size="60">
          <img  :src="fotoAbogado" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title">
          RPA
        </v-list-item-title>
        <v-list-item-subtitle>
          {{nombreCompleto}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider ></v-divider>

    <v-list dense shaped>
      <v-list-item-group
        v-model="itemActual"
        color="primary"
      >
        <v-list-item v-for="item in items" :key="item.text" :to="item.path" @click="redirectToPath(item)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item @click="cerrarSesion()" v-if="!$vuetify.breakpoint.mdAndUp">
          <v-list-item-action>
            <v-icon color="error">mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="error--text">CERRAR SESIÓN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  
    <template v-slot:append v-if="$vuetify.breakpoint.mdAndUp">
      <v-list dense>
        <v-list-item @click="cerrarSesion()">
          <v-list-item-action>
            <v-icon color="error">mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="error--text">CERRAR SESIÓN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    
  </v-navigation-drawer>
</template>
<script>
import Auth from '@/mixins/auth'
import {mapGetters} from 'vuex'
export default {
  mixins:[Auth],
  data() {
    return {
      itemActual: 0,
    };
  },
  computed: {
    items() {
      return this.$store.getters.getMenuItemsByPosicion;
    },
    drawer: {
      get() {
        return this.$store.state.menu.drawer;
      },
      set(value) {
        if (!value) {
          this.$store.commit("setDrawer", false);
        }
      }
    },
    miniVariant: {
      get() {
        return this.$store.state.menu.miniVariant;
      },
      set(value) {
        if (!value) {
          this.$store.commit("setMiniVariant", false);
        }
      }
    },
    clipped: {
      get() {
        return this.$store.state.menu.clipped;
      },
      set(value) {
        if (!value) {
          this.$store.commit("setClipped", false);
        }
      }
    },
    ...mapGetters('abogado',{fotoAbogado:'getFotoAbogado',nombreCompleto:'getNombreCompleto'}),
  },
  methods: {
    redirectToPath(item) {
      this.$store.commit("setTituloPricipal", item.titulo);
    }
  }
};
</script>
<style>
.menu_active {
  background-color: #253854 !important ;
  color: azure !important;
}
.menu_active :hover {
  background-color: #253854 !important ;
  color: azure !important;
}
</style>

