import {formatDateGlobal} from './funcionesFecha'

export function parseDataPersona(persona){
    return {
        nombres: persona.nombres,
        primerApellido: persona.primer_apellido,
        segundoApellido: persona.segundo_apellido,
        numeroDocumento: persona.numero_documento,
        complementoDocumento: persona.complemento_documento,
        lugarEmisionDocumento: persona.lugar_emision_documento?persona.lugar_emision_documento.nombre:null,
        tipoDocumento:persona.tipo_documento?persona.tipo_documento.nombre:null,
        fechaNacimiento: formatDateGlobal(persona.fecha_nacimiento),
        celular: persona.celular,
        correo: persona.correo,
        whatsapp:persona.whatsapp,
        genero: persona.genero
      }
}

export function parseDataRegistroAbogado(abogado){
    return {
        id:abogado.id,
        matricula: abogado.matricula,
        fechaCreacion: abogado.fecha_creacion,
        fechaValidacion: abogado.fecha_validacion,
        lugarRegistro: abogado.lugar_registro.nombre,
        firmaAbogado: abogado.firma_abogado,
        fotoAbogado: abogado.foto_abogado,
        tituloPdf: abogado.tituloPdf,
        codigoNumeroRegistroRpa: abogado.codigo_numero_registro_rpa,
        tipoRegistro: abogado.tipo_registro.nombre,
        estadoAbogado: abogado.estado_abogado.nombre,
        estadoRegistro: abogado.estado_registro.nombre,
        metodoPago:abogado.metodo_pago,
        fechaCredencialVencimiento:abogado.fecha_credencial_vencimiento
    }
}

export function parseDataCertificacionTitulo(abogado){
    return {
        procedencia: abogado.certificacion_titulo.procedencia.nombre_universidad,
        fechaRecepcion: abogado.certificacion_titulo.fecha_hoja_ruta,
        hojaRuta: abogado.certificacion_titulo.hoja_ruta
    }
}

export function parseDataDirecciones(direcciones=[]) {
    var arrayDireccion = []
    direcciones.forEach(element => {
      arrayDireccion.push({
        direccion: element.direccion,
        id:element.id,
        fechaCreacion: formatDateGlobal(element.fecha_creacion),
        departamento: element.departamento?.id,
        municipio: element.municipio,
        departementoText:element.departamento?.nombre,
        nro: element.nro,
        telefono: element.telefono,
        tipo: element.tipo.nombre,
        activo: element.activo,
        latitud: element.lat?element.lat:-16.41,
        longitud: element.long?element.long:-69.991,
        certificado: !!element.certificado,
        principal:element.principal,
        estado:element.estado
      })
    });
    return arrayDireccion;
}

export function parseDataProfesion(abogado){
    return {
        fechaExpedicionTitulo: abogado.fecha_expedicion_titulo,
        numeroTitulo: abogado.numero_titulo,
        fotocopiaTituloLegalizada: abogado.fotocopia_titulo_legalizada ? 'SI' : 'NO',
        lugarVerificacionTitulo: abogado.lugar_verificacion_titulo?abogado.lugar_verificacion_titulo.nombre_universidad:null,
        mencion: abogado.mencion,
        universidadInicio: abogado.universidad_inicio.nombre_universidad,
        universidadEgreso: abogado.universidad_egreso.nombre_universidad,
        universidadLicenciatura: abogado.universidad_licenciatura.nombre_universidad
    }
}